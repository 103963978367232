import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import Appcontainer from './appcontainer';



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Appcontainer tab="home" />)

if (module.hot) { // enables hot module replacement if plugin is installed
 module.hot.accept();
}