/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { useParams, Navigate } from "react-router-dom";
import axios from "axios";
import { API_CONFIG, CYBER_org_id } from "../Config/config-env.js";
import Loader from "../components/Loader.js";
import { isMobile, getDateCyberFormat } from "../utils/functions.js";
const Home = () => {
  const { company_code, qr_id } = useParams();

  // Renderiza el componente de clase Home y pasa los parámetros de la URL como props
  return <Class company_code={company_code} qr_id={qr_id} />;
};
var contadorPeticiones = 0;
var maxPeticiones = 50;
class Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      company_code: "",
      qr_id: "",
      amount: 0,
      isValid: true,
      company_name: "",
      card_expiry_date: "",
      URLCYBER: "",
      invalid: false,
      loading: true,
      redirect: false,
      qr_image: "",
    };
  }

  getQRBMSCById = async () => {
    const { qr_id } = this.props;
    await axios({
      url: API_CONFIG.base_url + "/getQRBMSCById",
      data: {
        id: qr_id,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.status) {
          this.setState({
            qr_image: "data:image/jpeg;base64," + data.qr,
            qr_message: "¡Escanea este QR y paga!",
          });
        } else {
          this.setState({
            qr_image:
              "https://images-quickseller.s3.amazonaws.com/qr_broken.png",
            qr_message:
              "Hubo un problema generando el QR, aún puede pagar con Tarjeta de crédito/détito",
          });
        }
      })
      .catch(function (error) {
        console.log(">>>>>>>>", error);
      });
  };

  componentDidMount = async () => {
    this.getQRBMSCById();
  };

  render() {
    return (
      this.state.qr_image !== "" && (
        <>
          <img id="qr_image" name="qr_image" src={this.state.qr_image} style={{width:"100%"}} />
        </>
      )
    );
  }
}
export default Home;
