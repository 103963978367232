import React from "react";
import { API_CONFIG } from "../Config/config-env.js";
import axios from "axios";
export async function decodeToken(token) {
  var resp = { resp: "NOK", datos: null };
  await axios({
    url: API_CONFIG.base_url + "/decodeToken",
    method: "post",
    data: { token: token },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = data;
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export function getDateCyberFormat() {
  var date = new Date(); //will convert to present timestamp offset
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  var mes = "",
    dia = "",
    hora = "",
    minuto = "",
    segundos = "";
  var anio = date.getFullYear();
  if (date.getMonth() === 0) {
    mes = "01";
  } else if (date.getMonth() < 9) {
    mes = "0" + (date.getMonth() + 1);
  } else {
    mes = date.getMonth() + 1;
  }
  if (date.getDate() < 10) {
    dia = "0" + date.getDate();
  } else {
    dia = date.getDate() + "";
  }
  if (date.getHours() < 10) {
    hora = "0" + date.getHours();
  } else {
    hora = date.getHours() + "";
  }
  if (date.getMinutes() < 10) {
    minuto = "0" + date.getMinutes();
  } else {
    minuto = date.getMinutes() + "";
  }
  if (date.getSeconds() < 10) {
    segundos = "0" + date.getSeconds();
  } else {
    segundos = date.getSeconds() + "";
  }
  var fecha =
    anio +
    "-" +
    mes +
    "-" +
    dia +
    "T" +
    hora +
    ":" +
    minuto +
    ":" +
    segundos +
    "Z";
  return fecha;
}
export async function generatePayment(company_code, order_id) {
  var resp = {
    desde: null,
    hasta: null,
    dias_max_reserva: null,
  };
  await axios({
    url: API_CONFIG.base_url + "/generate-payment",
    method: "post",
    data: {
      company_code,
      order_id,
    },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = data;
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
 }

export function onShowSizeChange(obj) {}

export function getChildren(_jsonObjet, _children) {
  let respChildObj;
  for (let key in _jsonObjet) {
    if (_jsonObjet[key].isChildren && _jsonObjet[key].name === _children) {
      respChildObj = _jsonObjet[key];
    }
  }
  return respChildObj;
}
export function itemRender(current, type, originalElement) {
  if (type === "prev") {
    return <a href="#0">Previous</a>;
  }
  if (type === "next") {
    return <a href="#0">Next</a>;
  }
  return originalElement;
}

export function find(_json, _value, _key) {
  var pos = -1;

  _json.map(function (key, item) {
    if (key[_key] == _value) {
      pos = item;
    }
  });
  return pos;
}

export function cargarSonido(fuente) {
  const sonido = document.createElement("audio");
  sonido.src = fuente;
  sonido.setAttribute("preload", "auto");
  sonido.setAttribute("controls", "none");
  sonido.style.display = "none"; // <-- oculto
  document.body.appendChild(sonido);
  return sonido;
}

export function findObjName(_event) {
  var continueSerching = true;
  var objName = "";

  var event = _event.target;

  while (continueSerching) {
    if (event.name || event.attributes.name) {
      continueSerching = false;
      objName = event.name ? event.name : event.attributes.name.nodeValue;
    } else {
      event = event.parentNode;
    }
  }

  return objName;
}

export function buscarCoincidencias(datos, texto, propiedad) {
  // Convertir el texto de búsqueda a minúsculas para hacer la búsqueda sin distinción entre mayúsculas y minúsculas
  texto = texto.toLowerCase();

  // Array para almacenar los resultados de la búsqueda
  var resultados = [];

  // Recorrer cada objeto en el array
  if (texto && texto.length > 3) {
    for (let i = 0; i < datos.length; i++) {
      var objeto = datos[i];

      // Recorrer cada propiedad del objeto

      // Verificar si el valor de la propiedad contiene el texto de búsqueda
      if (
        objeto[propiedad] &&
        objeto[propiedad].toString().toLowerCase().indexOf(texto) >= 0
      ) {
        // Si hay coincidencia, agregar el objeto entero a los resultados
        resultados.push(objeto);
      }
    }
    return resultados;
  } else {
    return datos;
  }
}

export async function getDiasParaReservar() {
  var resp = {
    desde: null,
    hasta: null,
    dias_max_reserva: null,
  };
  await axios({
    url: API_CONFIG.base_url + "/getDiasParaReservar",
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var f = new Date();
      f.setDate(f.getDate() + data.min_dias);
      var mes =
        f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1;
      var dia = f.getDate() < 10 ? "0" + f.getDate() : f.getDate();
      var desde = f.getFullYear() + "-" + mes + "-" + dia;

      f.setDate(f.getDate() + (data.min_dias + data.cantidad_dias + 5));
      mes = f.getMonth() + 1 < 10 ? "0" + (f.getMonth() + 1) : f.getMonth() + 1;
      dia = f.getDate() < 10 ? "0" + f.getDate() : f.getDate();
      var hasta = f.getFullYear() + "-" + mes + "-" + dia;
      resp = {
        desde: desde,
        hasta: hasta,
        cantidad_dias: data.cantidad_dias,
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getConsultoriosByIdCiudad(v_id_ciudad) {
  var resp = { consultorios: null };

  await axios({
    url: API_CONFIG.base_url + "/getConsultoriosByIdCiudad",
    method: "post",
    data: { id_ciudad: v_id_ciudad },
  })
    .then((response) => response.data)
    .then((data) => {
      var consultorios = [
        {
          text: "Elija un consultorio",
          value: "",
        },
      ];
      for (var key in data.rows) {
        consultorios.push({
          text:
            data.rows[key].consultorio +
            " " +
            data.rows[key].direccion +
            " " +
            data.rows[key].zona,
          consultorio: data.rows[key].consultorio,
          value: data.rows[key].idConsultorio,
          zona: data.rows[key].zona,
          direccion: data.rows[key].direccion,
          latitud: data.rows[key].latitud,
          longitud: data.rows[key].longitud,
        });
      }
      resp = { consultorios: consultorios };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getConsultorios() {
  var resp = { consultorios: null };

  await axios({
    url: API_CONFIG.base_url + "/getConsultorios",
    method: "post",
    data: {},
  })
    .then((response) => response.data)
    .then((data) => {
      var consultorios = [
        {
          text: "Todos",
          value: 0,
        },
      ];
      for (var key in data.rows) {
        consultorios.push({
          text: data.rows[key].consultorio + " - " + data.rows[key].zona,
          consultorio: data.rows[key].consultorio,
          value: parseInt(data.rows[key].idConsultorio),
          zona: data.rows[key].zona,
          direccion: data.rows[key].direccion,
          latitud: data.rows[key].latitud,
          longitud: data.rows[key].longitud,
        });
      }
      resp = { consultorios: consultorios };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getReglas() {
  var resp = { ciudades: [] };
  await axios({
    url: API_CONFIG.base_url + "/api/getReglas",
    data: { token: getCookie("tokenINNOVA") },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      resp = data;
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getCiudades() {
  var resp = { ciudades: [] };
  await axios({
    url: API_CONFIG.base_url + "/getCiudades",
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var ciudades = [{ text: "Elija una ciudad", value: "" }];
      for (var key in data.rows) {
        ciudades.push({
          text: data.rows[key].ciudad,
          value: data.rows[key].id_ciudad,
        });
      }
      resp = { ciudades: ciudades };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getMensajes() {
  var resp = {};
  await axios({
    url: API_CONFIG.base_url + "/getMensajes",

    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var mensajes_inferiores = [];
      var mensajes_inferiores_zoom = [];
      var mensajes_inferiores_whats = [];
      var mensajes_consultorio = [];
      let videos = [];
      var mensaje_fuera_horario,
        cita_reservada,
        paciente_no_encontrado,
        tiempo_mensajes,
        mensaje_atraso,
        mensaje_bienvenida_reserva,
        mensaje_alta_reserva,
        mensaje_alta_reserva2,
        mensaje_cambio_reserva,
        mensaje_cancelacion_reserva,
        mensaje_reserva_existente,
        mensaje_tiempo_cancelacion,
        mensaje_tiempo_cambio,
        mensaje_elija_reverva,
        mensaje_confirmar_fecha,
        mensaje_soporte_tecnico,
        mensaje_confidencialidad,
        direccion,
        ubicacion,
        mensaje_error_compra,
        mensaje_timeout_compra,
        mensaje_facturacion,
        mensaje_seleccione_fechahora,
        mensaje_alta_plan,
        url_calificacion,
        horas_cambiar_cita,
        horas_cancelar_cita,
        horas_minino_reserva;
      for (var key in data.rows) {
        if (data.rows[key].nombre === "mensajes_inferiores")
          mensajes_inferiores.push(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "mensajes_inferiores_zoom")
          mensajes_inferiores_zoom.push(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "mensajes_inferiores_whats")
          mensajes_inferiores_whats.push(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "mensaje_alta_reserva")
          mensaje_alta_reserva = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_alta_reserva2")
          mensaje_alta_reserva2 = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_cambio_reserva")
          mensaje_cambio_reserva = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_cancelacion_reserva")
          mensaje_cancelacion_reserva = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_reserva_existente")
          mensaje_reserva_existente = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_tiempo_cancelacion")
          mensaje_tiempo_cancelacion = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_tiempo_cambio")
          mensaje_tiempo_cambio = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_elija_reverva")
          mensaje_elija_reverva = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_confirmar_fecha")
          mensaje_confirmar_fecha = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_soporte_tecnico")
          mensaje_soporte_tecnico = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_confidencialidad")
          mensaje_confidencialidad = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "direccion")
          direccion = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "ubicacion")
          ubicacion = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_error_compra")
          mensaje_error_compra = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_timeout_compra")
          mensaje_timeout_compra = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_facturacion")
          mensaje_facturacion = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_seleccione_fechahora")
          mensaje_seleccione_fechahora = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_alta_plan")
          mensaje_alta_plan = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensajes_consultorio")
          mensajes_consultorio.push(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "url_calificacion")
          url_calificacion = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_bienvenida_reserva")
          mensaje_bienvenida_reserva = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "mensaje_atraso")
          mensaje_atraso = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "tiempo_mensajes")
          tiempo_mensajes = parseInt(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "cita_reservada")
          cita_reservada = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "paciente_no_encontrado")
          paciente_no_encontrado = data.rows[key].mensaje;
        else if (data.rows[key].dominio === "videos")
          videos.push(data.rows[key].mensaje);
        else if (data.rows[key].nombre === "mensaje_fuera_horario")
          mensaje_fuera_horario = data.rows[key].mensaje;
        else if (data.rows[key].nombre === "horas_cambiar_cita")
          horas_cambiar_cita = data.rows[key].valor_numerico;
        else if (data.rows[key].nombre === "horas_cancelar_cita")
          horas_cancelar_cita = data.rows[key].valor_numerico;
        else if (data.rows[key].nombre === "horas_minino_reserva")
          horas_minino_reserva = data.rows[key].valor_numerico;
      }
      resp = {
        mensajes_inferiores: mensajes_inferiores,
        mensajes_inferiores_whats: mensajes_inferiores_whats,
        mensajes_inferiores_zoom: mensajes_inferiores_zoom,
        mensaje_alta_reserva: mensaje_alta_reserva,
        mensaje_alta_reserva2: mensaje_alta_reserva2,
        mensaje_cambio_reserva: mensaje_cambio_reserva,
        mensaje_cancelacion_reserva: mensaje_cancelacion_reserva,
        mensaje_reserva_existente: mensaje_reserva_existente,
        mensaje_tiempo_cancelacion: mensaje_tiempo_cancelacion,
        mensaje_tiempo_cambio: mensaje_tiempo_cambio,
        mensaje_elija_reverva: mensaje_elija_reverva,
        mensaje_confirmar_fecha: mensaje_confirmar_fecha,
        mensaje_soporte_tecnico: mensaje_soporte_tecnico,
        mensaje_confidencialidad: mensaje_confidencialidad,
        cita_reservada: cita_reservada,
        paciente_no_encontrado: paciente_no_encontrado,
        direccion: direccion,
        ubicacion: ubicacion,
        mensaje_error_compra: mensaje_error_compra,
        mensaje_timeout_compra: mensaje_timeout_compra,
        mensaje_facturacion: mensaje_facturacion,
        mensaje_seleccione_fechahora: mensaje_seleccione_fechahora,
        mensaje_alta_plan: mensaje_alta_plan,
        mensajes_consultorio: mensajes_consultorio,
        url_calificacion: url_calificacion,
        mensaje_bienvenida_reserva: mensaje_bienvenida_reserva,
        mensaje_atraso: mensaje_atraso,
        minutos: tiempo_mensajes,
        mensaje_fuera_horario: mensaje_fuera_horario,
        videos: videos,
        horas_cambiar_cita: horas_cambiar_cita,
        horas_cancelar_cita: horas_cancelar_cita,
        horas_minino_reserva: horas_minino_reserva,
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function generateTempToken(productId, datosFactura) {
  var resp = { tempToken: null, datos: null };
  await axios({
    url: API_CONFIG.base_url + "/generateTempToken",
    data: { productId: productId, datosFactura: datosFactura },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { tempToken: data.token, datos: data.datos };
      }
    });
  return resp;
}

export async function getEstadoReserva(token, datos) {
  var resp = {
    estado_reserva_libre: null,
    estado_reserva: null,
    datosReserva: null,
  };
  await axios({
    url: API_CONFIG.base_url + "/getEstadoReserva",
    headers: { Authorization: "Bearer " + token },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var id_ciudad = -1;
      var ciudad = "";
      var hora = "";
      var fechaReserva;
      var hora_larga = "";
      var consultorio = -1;
      var tmp_id_cons = "";
      var estado_error = false;
      var dif_cambio_cita = 0;
      var dif_cancelar_cita = 0;
      var id_hora = 0;
      var id_consultorio = "";
      var tipo_reserva = "";
      var radioTipoReunionVirtual;
      var nombre_producto = datos.nombre_producto;
      var NumIdentificador = 0;
      var radioTipoReunionVirtual_orig;
      var horas_cambiar_cita;
      var dias_cambiar_cita;
      var estado_reserva_libre = false;
      var estado_reserva = data.estado_reserva;
      var Genero = datos.Genero ? datos.Genero : 1;
      var personal_meeting_url = datos.personal_meeting_url;
      var estado_formulario = data.estado_reserva ? "editar" : "registrar";
      var fechaSelected,
        fechaReserva,
        refreshing,
        tiempo_cambio,
        tiempo_cancelacion,
        fecha_tope_cambio,
        fecha_tope_cancelar,
        horas_cancelar_cita,
        dias_cancelar_cita;

      if (data.datos_reserva[0]) {
        if (data.datos_reserva[0].id_horario > 0) {
          fechaSelected = data.datos_reserva[0].fecha;

          hora = data.datos_reserva[0].hora;
          hora_larga = data.datos_reserva[0].hora_larga;
          id_hora = data.datos_reserva[0].id_horario;
          id_ciudad =
            /*data.datos_reserva[0]==="virtual"?-1: */ data.datos_reserva[0]
              .id_ciudad;
          tmp_id_cons =
            /*data.datos_reserva[0]==="virtual"?-1: */ data.datos_reserva[0]
              .id_consultorio;
          id_consultorio =
            /*data.datos_reserva[0]==="virtual"?-1: */ data.datos_reserva[0]
              .id_consultorio;
          tipo_reserva = data.datos_reserva[0].tipo_reserva;
          radioTipoReunionVirtual = datos.personal_meeting_url === null ? 0 : 1;
          radioTipoReunionVirtual_orig =
            datos.personal_meeting_url === null ? 0 : 1;
          horas_cambiar_cita = data.datos_reserva[0].horas_cambiar_cita;
          dias_cambiar_cita = data.datos_reserva[0].dias_cambiar_cita;
          horas_cancelar_cita = data.datos_reserva[0].horas_cancelar_cita;
          dias_cancelar_cita = data.datos_reserva[0].dias_cancelar_cita;
          let anio = parseInt(fechaSelected.substr(0, 4));
          let mes = parseInt(fechaSelected.substr(5, 2)) - 1;
          let dia = parseInt(fechaSelected.substr(8, 2));
          let hora_ = parseInt(hora_larga.substr(0, 2));
          let minuto = parseInt(hora_larga.substr(3, 2));
          fechaReserva = new Date(anio, mes, dia, hora_, minuto, 0, 0);
          var hoy = new Date().getTime();
          fecha_tope_cambio = new Date(
            hoy +
              1000 * 60 * 60 * data.datos_reserva[0].horas_cambiar_cita +
              1000 * 60 * 60 * 24 * data.datos_reserva[0].dias_cambiar_cita
          );

          fecha_tope_cancelar = new Date(
            hoy +
              1000 * 60 * 60 * data.datos_reserva[0].horas_cancelar_cita +
              1000 * 60 * 60 * 24 * data.datos_reserva[0].dias_cancelar_cita
          );
          if (fecha_tope_cambio < fechaReserva) {
            var diff = (fechaReserva - fecha_tope_cambio) / 1000;
            diff = Math.abs(Math.floor(diff));
            var days = Math.floor(diff / (24 * 60 * 60));
            var leftSec = diff - days * 24 * 60 * 60;
            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;
            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;
            refreshing = true;
            tiempo_cambio =
              days +
              " dia(s) " +
              hrs +
              " horas " +
              min +
              " minutos y " +
              leftSec +
              " segundos";
          } else {
            dif_cambio_cita = -1;
            tiempo_cambio = "Fuera de tiempo";
          }
          if (fecha_tope_cancelar < fechaReserva) {
            var diff = (fechaReserva - fecha_tope_cancelar) / 1000;
            diff = Math.abs(Math.floor(diff));

            var days = Math.floor(diff / (24 * 60 * 60));
            var leftSec = diff - days * 24 * 60 * 60;
            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;
            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;
            refreshing = true;
            tiempo_cancelacion =
              days +
              " dia(s) " +
              hrs +
              " horas " +
              min +
              " minutos y " +
              leftSec +
              " segundos";
          } else {
            dif_cancelar_cita = -1;
            tiempo_cancelacion = "Fuera de tiempo";
          }
        }
      } else {
        estado_reserva_libre = true;
      }

      resp = {
        estado_reserva_libre: estado_reserva_libre,
        estado_reserva: estado_reserva,
        datosReserva: {
          tipo_reserva: tipo_reserva,
          //  ciudad: ciudad,
          // consultorio: consultorio,
          estado_reserva: estado_reserva,
          estado_formulario: estado_formulario,
          fechaSelected: fechaSelected,
          fecha: fechaSelected,
          fecha_reserva: fechaSelected,
          fecha_reserva_original: fechaSelected,
          hora: hora,
          hora_larga: hora_larga,
          hora_original: hora,
          ciudad: datos.ciudad,
          consultorio: datos.consultorio,
          razon_social: datos.razon_social,
          numero_factura: datos.numero_factura,
          nit: datos.nit,
          nombre_cliente: datos.nombre_cliente,
          email_cliente: datos.email_cliente,
          apellido_paterno: datos.apellido_paterno,
          apellido_materno: datos.apellido_materno,
          ci_cliente: datos.ci_cliente,
          id_cliente: datos.id_cliente,
          Id_afiliado: datos.Id_afiliado,
          ci_afiliado: datos.ci_afiliado,
          Tipo_doc_afiliado: datos.Tipo_doc_afiliado,
          telefono_cliente: datos.telefono_cliente,
          id_ciudad: tipo_reserva === "virtual" ? -1 : id_ciudad,
          id_consultorio: tipo_reserva === "virtual" ? -1 : id_consultorio,
          id_consultorio_original:
            tipo_reserva === "virtual" ? -1 : id_consultorio,
          id_hora: id_hora,
          id_hora_original: id_hora,
          dif_cambio_cita: dif_cambio_cita,
          dif_cancelar_cita: dif_cancelar_cita,
          radioGenero: Genero,
          id_producto: datos.id_producto,
          personal_meeting_url: personal_meeting_url,
          nombre_producto: nombre_producto,
          direccion: datos.direccion,
          zona: datos.zona,
          latitud: datos.latitud,
          longitud: datos.longitud,
          fechaReserva: fechaReserva,
          radioTipoReunionVirtual: radioTipoReunionVirtual,
          radioTipoReunionVirtual_orig: radioTipoReunionVirtual_orig,
          horas_cambiar_cita: horas_cambiar_cita,
          dias_cambiar_cita: dias_cambiar_cita,
          horas_cancelar_cita: horas_cancelar_cita,
          dias_cancelar_cita: dias_cancelar_cita,
          tiempo_cambio: tiempo_cambio,
          tiempo_cancelacion: tiempo_cancelacion,
        },
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}
export async function getClienteByCI(v_ci_cliente, v_tipo_doc) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/getClienteByCI",
    data: { ci_cliente: v_ci_cliente, tipo_doc: v_tipo_doc ? v_tipo_doc : "C" },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        var f = new Date(data.rows.Fecha_nac);
        var FechaNaci =
          f.getFullYear() +
          "" +
          (f.getMonth() + 1 < 10
            ? "0" + (f.getMonth() + 1)
            : f.getMonth() + 1) +
          (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
        var Beneficiarios = [];
        if (data.resultBenef.length > 0) {
          Beneficiarios.push({
            id_cliente: data.rows.ID,
            nombre_cliente: data.rows.Nombres,
            apellido_paterno: data.rows.Papellido,
            apellido_materno: data.rows.Sapellido,
            radioGenero: data.rows.PatientGender === "Femenino" ? 1 : 2,
            telefono_cliente: data.rows.PatientContno,
            email_cliente: data.rows.PatientEmail,
            FechaNaci: FechaNaci,
            Id_afiliado: "",
            Tipo: data.rows.Tipo === "Afiliado" ? 1 : 2,
            ci_afiliado: "",
            Tipo_doc_afiliado: "",
            ci_cliente: data.rows.CI,
            TipoDoc: data.rows.Tipo_doc,
          });

          for (var i in data.resultBenef) {
            let f = new Date(data.resultBenef[i].Fecha_nac);
            let FechaNaci =
              f.getFullYear() +
              "" +
              (f.getMonth() + 1 < 10
                ? "0" + (f.getMonth() + 1)
                : f.getMonth() + 1) +
              (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
            Beneficiarios.push({
              id_cliente: data.resultBenef[i].ID,
              nombre_cliente: data.resultBenef[i].Nombres,
              apellido_paterno: data.resultBenef[i].Papellido,
              apellido_materno: data.resultBenef[i].Sapellido,
              radioGenero:
                data.resultBenef[i].PatientGender === "Femenino" ? 1 : 2,
              telefono_cliente: data.resultBenef[i].PatientContno,
              email_cliente: data.resultBenef[i].PatientEmail,
              FechaNaci: FechaNaci,
              Id_afiliado: data.resultBenef[i].Id_afiliado,
              Tipo: data.resultBenef[i].Tipo === "Afiliado" ? 1 : 2,
              ci_afiliado: data.rows.CI,
              Tipo_doc_afiliado: data.rows.Tipo_doc,
              ci_cliente: data.resultBenef[i].CI,
              TipoDoc: data.resultBenef[i].Tipo_doc,
            });
          }
        }

        resp = {
          bloquear_datos: true,
          id_cliente: data.rows.ID,
          nombre_cliente: data.rows.Nombres,
          apellido_paterno: data.rows.Papellido,
          apellido_materno: data.rows.Sapellido,
          radioGenero: data.rows.PatientGender === "Femenino" ? 1 : 2,
          telefono_cliente: data.rows.PatientContno,
          email_cliente: data.rows.PatientEmail,
          FechaNaci: FechaNaci,
          Id_afiliado: data.rows.Id_afiliado,
          Tipo: data.rows.Tipo === "Afiliado" ? 1 : 2,
          ci_afiliado: data.rows.ci_afiliado,
          Tipo_doc_afiliado: data.rows.Tipo_doc_afiliado,
          ci_cliente: data.rows.CI,
          TipoDoc: data.rows.Tipo_doc,
          Beneficiarios: Beneficiarios,
        };
      } else {
        resp = {
          bloquear_datos: false,
          nombre_cliente: "",
          apellido_paterno: "",
          apellido_materno: "",
          telefono_cliente: "",
          email_cliente: "",
          radioGenero: 1,
          Id_afiliado: "",
          Tipo: "",
          ci_afiliado: "",
          Tipo_doc_afiliado: "C",
          ci_cliente: "",
          TipoDoc: "C",
          Beneficiarios: [],
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { bloquear_datos: false, error: true };
    });
  return resp;
}

export async function getClienteByNumIdentif(v_num_identif) {
  var resp;
  await axios({
    //url: API_CONFIG.base_url+"/crediseguro/SolConsultaAfiliadosByNumIdentificador_new",
    url:
      API_CONFIG.base_url +
      "/crediseguro/SolConsultaAfiliadosByNumIdentificador",
    data: {
      user: API_CONFIG.user,
      psw: API_CONFIG.paswword,
      numIdentificador: v_num_identif,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        var Beneficiarios = [];
        if (data.Beneficiarios.length > 0) {
          resp = {
            bloquear_datos: true,
            id_cliente: data.Beneficiarios[0].IdBeneficiario,
            nombre_cliente: data.Beneficiarios[0].Pnombre,
            apellido_paterno: data.Beneficiarios[0].Papellido,
            apellido_materno: data.Beneficiarios[0].Sapellido,
            //radioGenero       : data.Beneficiarios[0].PatientGender==="Femenino"?1:2,
            telefono_cliente: data.Beneficiarios[0].Telefono,
            email_cliente: data.Beneficiarios[0].Correo,
            //FechaNaci         : data.Beneficiarios[0].,
            Id_afiliado: data.Beneficiarios[0].IdBeneficiario,
            Tipo: data.Beneficiarios[0].Tipo,
            ci_afiliado: data.Beneficiarios[0].DocIdentidad,
            Tipo_doc_afiliado: data.Beneficiarios[0].TipoDoc,
            ci_cliente: data.Beneficiarios[0].DocIdentidad,
            TipoDoc: data.Beneficiarios[0].TipoDoc,
          };
        } else {
          resp = {
            bloquear_datos: false,
            nombre_cliente: "",
            apellido_paterno: "",
            apellido_materno: "",
            telefono_cliente: "",
            email_cliente: "",
            radioGenero: 1,
            Id_afiliado: "",
            Tipo: "",
            ci_afiliado: "",
            Tipo_doc_afiliado: "C",
            ci_cliente: "",
            TipoDoc: "C",
            Beneficiarios: [],
          };
        }
      } else {
        resp = {
          bloquear_datos: false,
          nombre_cliente: "",
          apellido_paterno: "",
          apellido_materno: "",
          telefono_cliente: "",
          email_cliente: "",
          radioGenero: 1,
          Id_afiliado: "",
          Tipo: "",
          ci_afiliado: "",
          Tipo_doc_afiliado: "C",
          ci_cliente: "",
          TipoDoc: "C",
          Beneficiarios: [],
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { bloquear_datos: false, error: true };
    });
  return resp;
}

export async function getClienteByCIV2(v_ci_cliente) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/api/SolConsultaPersona",
    data: { DocIdentidad: v_ci_cliente },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        //var f = new Date(data.rows.Fecha_nac)
        //var FechaNaci = (f.getFullYear()+"") +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) ;

        var Beneficiarios = [];
        /*
			if ( data.resultBenef.length>0){
				Beneficiarios.push({id_cliente           : data.rows.ID ,
														nombre_cliente       : data.rows.Nombres,
															apellido_paterno     : data.rows.Papellido ,
															apellido_materno     : data.rows.Sapellido , 
															radioGenero          : data.rows.PatientGender==="Femenino"?1:2 ,
															telefono_cliente     : data.rows.PatientContno ,
															email_cliente        : data.rows.PatientEmail ,
															FechaNaci            : FechaNaci ,
															Id_afiliado          : "" ,
															Tipo                 : data.rows.Tipo==="Afiliado"?1:2,
															ci_afiliado          : "",
															Tipo_doc_afiliado    : "" ,
															ci_cliente           : data.rows.CI ,
															TipoDoc              : data.rows.Tipo_doc})
															
				for (var i in data.resultBenef ){
					let f = new Date(data.resultBenef[i].Fecha_nac)
					let FechaNaci = (f.getFullYear()+"") +  ((f.getMonth()+1 )<10? "0"+(f.getMonth()+1): (f.getMonth()+1 )) + ((f.getDate() )<10? "0"+(f.getDate()): (f.getDate() )) ; 
						Beneficiarios.push({id_cliente           : data.resultBenef[i].ID ,
																nombre_cliente       : data.resultBenef[i].Nombres,
																apellido_paterno     : data.resultBenef[i].Papellido ,
																apellido_materno     : data.resultBenef[i].Sapellido , 
																radioGenero          : data.resultBenef[i].PatientGender==="Femenino"?1:2 ,
																telefono_cliente     : data.resultBenef[i].PatientContno ,
																email_cliente        : data.resultBenef[i].PatientEmail ,
																FechaNaci            : FechaNaci ,
																Id_afiliado          : data.resultBenef[i].Id_afiliado ,
																Tipo                 : data.resultBenef[i].Tipo==="Afiliado"?1:2,
																ci_afiliado          : data.rows.CI ,
																Tipo_doc_afiliado    : data.rows.Tipo_doc ,
																ci_cliente           : data.resultBenef[i].CI ,
																TipoDoc              : data.resultBenef[i].Tipo_doc} )
				}
			} 
			*/
        resp = {
          bloquear_datos: true,
          id_cliente: data.data.IdPaciente,
          nombre_cliente:
            data.data.Pnombre + (data.Snombre ? " " + data.Snombre : ""),
          apellido_paterno: data.data.Papellido,
          apellido_materno: data.data.Sapellido,
          //radioGenero       : data.rows.PatientGender==="Femenino"?1:2,
          telefono_cliente: data.data.Telefono,
          email_cliente: data.data.Correo,
          //FechaNaci         : FechaNaci,
          Id_afiliado: data.data.IdPaciente,
          Tipo: data.data.Tipo,
          ci_afiliado: data.data.DocIdentidad,
          Tipo_doc_afiliado: data.data.Tipo_doc_afiliado,
          ci_cliente: data.data.DocIdentidad,
          TipoDoc: data.data.Tipo_doc,
          Beneficiarios: Beneficiarios,
        };
      } else {
        resp = {
          bloquear_datos: false,
          nombre_cliente: "",
          apellido_paterno: "",
          apellido_materno: "",
          telefono_cliente: "",
          email_cliente: "",
          //radioGenero       : 1,
          Id_afiliado: "",
          Tipo: "",
          ci_afiliado: "",
          Tipo_doc_afiliado: "C",
          ci_cliente: "",
          TipoDoc: "C",
          Beneficiarios: [],
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { bloquear_datos: false, error: true };
    });
  return resp;
}

export async function getClienteById(v_id_cliente, v_NumIdentificador) {
  var resp = { bloquear_datos: false };
  await axios({
    url: API_CONFIG.base_url + "/getClienteById",
    data: { id_cliente: v_id_cliente, NumIdentificador: v_NumIdentificador },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        var f = new Date(data.rows.Fecha_nac);
        var FechaNaci =
          f.getFullYear() +
          "" +
          (f.getMonth() + 1 < 10
            ? "0" + (f.getMonth() + 1)
            : f.getMonth() + 1) +
          (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
        var Beneficiarios = [];
        var Id_afiliado = "";
        var ci_afiliado = "";
        var Tipo_doc_afiliado = "";
        if (data.resultBenef.length > 0) {
          for (var i in data.resultBenef) {
            let f = new Date(data.resultBenef[i].Fecha_nac);
            let FechaNaci =
              f.getFullYear() +
              "" +
              (f.getMonth() + 1 < 10
                ? "0" + (f.getMonth() + 1)
                : f.getMonth() + 1) +
              (f.getDate() < 10 ? "0" + f.getDate() : f.getDate());
            if (data.resultBenef[i].Tipo === "Afiliado") {
              Id_afiliado = data.resultBenef[i].ID;
              ci_afiliado = data.resultBenef[i].CI;
              Tipo_doc_afiliado = data.resultBenef[i].Tipo_doc;
            }

            Beneficiarios.push({
              id_cliente: data.resultBenef[i].ID,
              nombre_cliente: data.resultBenef[i].Nombres,
              apellido_paterno: data.resultBenef[i].Papellido,
              apellido_materno: data.resultBenef[i].Sapellido,
              radioGenero:
                data.resultBenef[i].PatientGender === "Femenino" ? 1 : 2,
              telefono_cliente: data.resultBenef[i].PatientContno,
              email_cliente: data.resultBenef[i].PatientEmail,
              FechaNaci: FechaNaci,
              Id_afiliado: data.resultBenef[i].Id_afiliado,
              Tipo: data.resultBenef[i].Tipo === "Afiliado" ? 1 : 2,
              ci_afiliado: data.rows.CI,
              Tipo_doc_afiliado: data.rows.Tipo_doc,
              ci_cliente: data.resultBenef[i].CI,
              TipoDoc: data.resultBenef[i].Tipo_doc,
            });
          }
        }

        resp = {
          bloquear_datos: true,
          id_cliente: data.rows.ID,
          nombre_cliente: data.rows.Nombres,
          apellido_paterno: data.rows.Papellido,
          apellido_materno: data.rows.Sapellido,
          radioGenero: data.rows.PatientGender === "Femenino" ? 1 : 2,
          telefono_cliente: data.rows.PatientContno,
          email_cliente: data.rows.PatientEmail,
          Tipo: data.rows.Tipo === "Afiliado" ? 1 : 2,
          FechaNaci: FechaNaci,
          Id_afiliado: Id_afiliado,
          ci_afiliado: ci_afiliado,
          Tipo_doc_afiliado: Tipo_doc_afiliado,
          ci_cliente: data.rows.CI,
          TipoDoc: data.rows.Tipo_doc,
          Beneficiarios: Beneficiarios,
        };
      } else {
        resp = {
          bloquear_datos: false,
          nombre_cliente: "",
          apellido_paterno: "",
          apellido_materno: "",
          telefono_cliente: "",
          email_cliente: "",
          radioGenero: 1,
          Id_afiliado: "",
          Tipo: "",
          ci_afiliado: "",
          Tipo_doc_afiliado: "C",
          ci_cliente: "",
          TipoDoc: "C",
          Beneficiarios: [],
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getDiasByIdConsultorio(
  v_id_consultorio,
  v_desde,
  v_hasta,
  dias_max_reserva
) {
  var resp = { dias: [] };
  await axios({
    url: API_CONFIG.base_url + "/getDiasByIdConsultorio",
    data: { id_consultorio: v_id_consultorio, desde: v_desde, hasta: v_hasta },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var dias = [];

      if (data.rows) {
        var cant_dias =
          data.rows.length > dias_max_reserva
            ? dias_max_reserva
            : data.rows.length;

        var dias_lit = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

        for (var i = 0; i < cant_dias; i++) {
          let fecha = new Date(data.rows[i].fecha);
          var dia_number = fecha.getDay();
          dias.push({
            fecha: data.rows[i].fecha,
            key: "fecha" + i,
            i: i,
            label:
              data.rows[i].fecha.substr(8, 2) +
              "-" +
              data.rows[i].fecha.substr(5, 2) +
              "-" +
              data.rows[i].fecha.substr(0, 4),
            dia: dias_lit[dia_number],
          });
        }
        resp = { dias: dias };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getDiasByIdConsultorioYServicio(
  v_id_consultorio,
  v_desde,
  v_hasta,
  dias_max_reserva,
  serviceType,
  id_servicio,
  customerCI,
  horas_minino_reserva
) {
  var resp = { dias: [] };
  await axios({
    url: API_CONFIG.base_url + "/getDiasByIdConsultorioYServicio",
    data: {
      id_consultorio: v_id_consultorio,
      desde: v_desde,
      hasta: v_hasta,
      dias_max_reserva: dias_max_reserva,
      serviceType: serviceType,
      id_servicio: id_servicio,
      cedula: customerCI,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var dias = [];

      if (data.rows) {
        var cant_dias =
          data.rows.length > dias_max_reserva
            ? dias_max_reserva
            : data.rows.length;

        var dias_lit = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"];

        for (var i = 0; i < cant_dias; i++) {
          let fecha = new Date(data.rows[i].fecha);
          var dia_number = fecha.getDay();

          let now = new Date();
          var dia =
            now.getFullYear() +
            "-" +
            (now.getMonth() + 1).toString().padStart(2, "0") +
            "-" +
            now.getDate().toString().padStart(2, "0");

          //console.log("fe",data.rows[i].fecha, dia)

          let horas = data.rows[i].horas;

          let hora = (now.getHours() + horas_minino_reserva) * 100;
          if (dia === data.rows[i].fecha) {
            horas.map(function (h) {
              if (h.horaIni < hora) {
                h["esReservada"] = true;
                h["estado"] = "vencida";
              }
            });
          }

          dias.push({
            fecha: data.rows[i].fecha,
            key: "fecha" + i,
            i: i,
            label:
              data.rows[i].fecha.substr(8, 2) +
              "-" +
              data.rows[i].fecha.substr(5, 2) +
              "-" +
              data.rows[i].fecha.substr(0, 4),
            dia: dias_lit[dia_number],
            horas: horas,
          });
        }
        resp = { dias: dias };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getHorasLibresByIdConsultorio(
  v_id_consultorio,
  v_fecha,
  v_hora,
  token,
  v_id_hora_original
) {
  var resp = { horas_libres: [] };
  await axios({
    url: API_CONFIG.base_url + "/getHorasLibresByIdConsultorio",
    headers: { Authorization: "Bearer " + token },
    data: { id_consultorio: v_id_consultorio, fecha: v_fecha },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var horas_libres_pre = [];
      if (data.rows) {
        for (var key in data.rows) {
          let esReservada =
            data.rows[key].paciente == "libre" ||
            v_id_hora_original == data.rows[key].id
              ? false
              : true;
          /*if(data.rows[key].id == id_hora_original){
					esReservada = false
				}*/
          horas_libres_pre.push({
            hora: data.rows[key].hora,
            hora_larga: data.rows[key].hora_larga,
            //	                  "key": "hora"+key,
            esReservada: esReservada,
            id_hora: data.rows[key].id,
            key: key,
            atendido: data.rows[key].atendido,
          });
        }
        var horas_libres = [];
        var ini = 0;
        var salto = 7;
        let campo0 = [];
        for (var i = 0; i < horas_libres_pre.length; i = i + 7) {
          campo0.push(horas_libres_pre[i]);
        }
        horas_libres.push(campo0);
        for (var j = 1; j <= 6; j++) {
          let campo = [];
          for (var i = j; i < horas_libres_pre.length; i = i + 7) {
            if (i < 28) {
              campo.push(horas_libres_pre[i]);
            }
          }
          horas_libres.push(campo);
        }
        resp = { horas_libres: horas_libres };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getHorasLibresByIdConsultorioSingle(
  v_id_consultorio,
  v_fecha,
  v_hora,
  token,
  v_id_hora_original
) {
  var resp = { horas_libres: [] };
  await axios({
    url: API_CONFIG.base_url + "/getHorasLibresByIdConsultorio",
    headers: { Authorization: "Bearer " + token },
    data: { id_consultorio: v_id_consultorio, fecha: v_fecha },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      var horas_libres_pre = [];
      if (data.rows) {
        for (var key in data.rows) {
          let esReservada =
            data.rows[key].paciente === "libre" ||
            data.rows[key].paciente === "pasado" ||
            v_id_hora_original == data.rows[key].id
              ? false
              : true;
          /*if(data.rows[key].id == id_hora_original){
					esReservada = false
				}*/
          horas_libres_pre.push({
            hora: data.rows[key].hora,
            hora_larga: data.rows[key].hora_larga,
            key: "hora" + key,
            esReservada: esReservada,
            id_hora: data.rows[key].id,
            cedula: data.rows[key].cedula,
            paciente: data.rows[key].paciente,
            atendido: data.rows[key].atendido,
          });
        }

        resp = { horas_libres: horas_libres_pre };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}
export async function setAtencion(token, id_hora) {
  var resp = { horas_libres: [] };
  await axios({
    url: API_CONFIG.base_url + "/setAtencion",
    headers: { Authorization: "Bearer " + token },
    data: {
      id_hora: id_hora,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: "ok" };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function setFinAtencion(token, id_hora) {
  var resp = { horas_libres: [] };
  await axios({
    url: API_CONFIG.base_url + "/setFinAtencion",
    headers: { Authorization: "Bearer " + token },
    data: {
      id_hora: id_hora,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      console.log("setFinAtencion", data);
      if (data.Estado === "E") {
        resp = { success: true, message: "ok" };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function setReserva(
  carnet_identidad,
  nombre,
  apellido,
  producto,
  token,
  selHora,
  id_hora,
  idPaciente
) {
  var resp = { horas_libres: [] };
  await axios({
    url: API_CONFIG.base_url + "/setReserva",
    headers: { Authorization: "Bearer " + token },
    data: {
      id_hora: id_hora,
      Pnombre: nombre,
      Papellido: apellido,
      carnet_identidad: carnet_identidad,
      Producto: producto,
      hora: selHora,
      idPaciente: idPaciente,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: "ok" };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function registraReserva(v_datos, token, tipoTransaccion) {
  var resp = { resp: "", desc: "" };
  var respuesta_final = "";
  var llave;

  console.log(v_datos, tipoTransaccion);

  await axios({
    url: API_CONFIG.base_url + "/registraReservaV2",
    data: {
      token: token,
      Pnombre: v_datos.nombre_cliente,
      Papellido: v_datos.apellido_paterno,
      email_cliente: v_datos.email_cliente,
      telefono_cliente: v_datos.telefono_cliente,
      DocIdentidad: v_datos.ci_cliente,
      TipoDoc: v_datos.TipoDoc === "" ? "C" : v_datos.TipoDoc,
      Sapellido: v_datos.apellido_materno,
      FechaNaci: v_datos.year + v_datos.month + v_datos.day,
      Genero: v_datos.radioGenero,
      CodOficina: "0102",
      fecha: v_datos.fechaSelected,
      hora: v_datos.hora,
      id_hora: v_datos.id_hora,
      id_consultorio: v_datos.id_consultorio,
      tipo_reserva: v_datos.radioTipoReunionVirtual,
      prereserva: tipoTransaccion,
      consultorio: v_datos.consultorio,
      direccion: v_datos.direccion,
      zona: v_datos.zona,
      ciudad: v_datos.ciudad,
      id_cliente: v_datos.id_cliente,
      ci_cliente: v_datos.ci_cliente,
      latitud: v_datos.latitud,
      longitud: v_datos.longitud,
      Id_afiliado: v_datos.Id_afiliado,
      Tipo: v_datos.Tipo === "" ? 1 : v_datos.Tipo,
      ci_afiliado: v_datos.ci_afiliado,
      Tipo_doc_afiliado: v_datos.Tipo_doc_afiliado,
      id_servicio: v_datos.id_servicio,
      id_plan: v_datos.planCode,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "X") {
        resp = { resp: "NOK", desc: data.message };
      } else {
        respuesta_final = "OK";
        llave = data.llave;
        resp = {
          resp: "OK",
          desc: data.message,
          estado_reserva: true,
          estado_formulario: "editar",
          reserva_editada: true,
          ultima_accion: "registrado",
          llave: data.llave,
          personal_meeting_url: data.personal_meeting_url,
        };
      }
    })
    .then((data) => {
      if (respuesta_final === "OK") {
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { resp: "NOK", desc: "ERROR DE SISTEMA:" + error };
    });

  return resp;
}

export async function cancelaReserva(token, id_hora) {
  var resp = { resp: "", desc: "" };
  var respuesta_final = "";
  var llave;
  await axios({
    url: API_CONFIG.base_url + "/cancelaReserva",
    method: "post",
    headers: { Authorization: "Bearer " + token },
    data: { id_hora: id_hora },
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "X") {
        resp = { resp: "NOK", desc: data.message };
      } else {
        resp = { resp: "OK", desc: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { resp: "NOK", desc: "ERROR DE SISTEMA:" + error };
    });
  return resp;
}

export function validateForm(
  v_validation,
  v_datos,
  toast,
  fieldName,
  fieldValue
) {
  var validation = v_validation;
  var isValid = true;
  var error = "";
  var resp = { validation: validation, isValid: isValid };
  var uniqueField = fieldName ? true : false;

  for (var key in validation) {
    if (uniqueField) {
      if (validation[key].name == fieldName) {
        validation[key].invalid = false;
        if (fieldValue === "" && validation[key].notRequired === undefined) {
          validation[key].invalid = true;
        } else {
          if (validation[key].type === "email" && fieldValue.length > 0) {
            var emailRegex =
              /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
            if (!emailRegex.test(fieldValue)) {
              validation[key].invalid = true;
            }
          } else if (validation[key].type === "select") {
          } else if (
            validation[key].type === "number" &&
            fieldValue.length > 0
          ) {
            if (isNaN(fieldValue)) {
              validation[key].invalid = true;
            }
          } else if (validation[key].type === "text") {
          } else if (
            validation[key].type === "phone" &&
            fieldValue.length > 0
          ) {
            if (isNaN(fieldValue)) {
              validation[key].invalid = true;
            } else {
              if (fieldValue < 60000000 || fieldValue > 80000000) {
                validation[key].invalid = true;
              }
            }
          } else {
            validation[key].invalid = false;
          }
        }
      }
    } else {
      validation[key].invalid = false;
      if (
        v_datos[validation[key].name] === "" &&
        validation[key].notRequired === undefined
      ) {
        isValid = false;
        validation[key].invalid = true;
        error = "Complete el campo " + validation[key].label;
        toast.warning(error, { closeButton: true });
      } else {
        if (
          validation[key].type === "email" &&
          v_datos[validation[key].name].length > 0
        ) {
          var emailRegex =
            /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i;
          if (!emailRegex.test(v_datos[validation[key].name])) {
            error =
              "El formato del " + validation[key].label + " no es correcto";
            toast.warning(error, { closeButton: true });
            isValid = false;
            validation[key].invalid = true;
          }
        } else if (validation[key].type === "select") {
        } else if (
          validation[key].type === "number" &&
          v_datos[validation[key].name].length > 0
        ) {
          if (isNaN(v_datos[validation[key].name])) {
            error =
              "El " +
              validation[key].label +
              " solo puede contener n\u00FAmeros";
            toast.warning(error, { closeButton: true });
            isValid = false;
            validation[key].invalid = true;
          }
        } else if (validation[key].type === "text") {
        } else if (
          validation[key].type === "phone" &&
          v_datos[validation[key].name].length > 0
        ) {
          if (isNaN(v_datos[validation[key].name])) {
            error =
              "El " +
              validation[key].label +
              " solo puede contener n\u00FAmeros";
            toast.warning(error, { closeButton: true });
            isValid = false;
            validation[key].invalid = true;
          } else {
            if (
              v_datos[validation[key].name] < 60000000 ||
              v_datos[validation[key].name] > 80000000
            ) {
              error = "El " + validation[key].label + " no es v\u00E1lido";
              toast.warning(error, { closeButton: true });
              isValid = false;
              validation[key].invalid = true;
            }
          }
        } else {
          validation[key].invalid = false;
        }

        if (
          validation[key].maxLength &&
          v_datos[validation[key].name] != "" &&
          !validation[key].invalid
        ) {
          if (
            v_datos[validation[key].name].length > validation[key].maxLength
          ) {
            error =
              "El campo " +
              validation[key].label +
              " supera la longitud de caracterers m\u00E1xima permitida." +
              "(ACTUAL:" +
              v_datos[validation[key].name].length +
              ",MAX:" +
              validation[key].maxLength +
              ").";
            toast.warning(error, { closeButton: true });
            isValid = false;
            validation[key].invalid = true;
          }
        }
        if (
          validation[key].minLength &&
          v_datos[validation[key].name] != "" &&
          !validation[key].invalid
        ) {
          if (
            v_datos[validation[key].name].length < validation[key].minLength
          ) {
            error =
              "El campo " +
              validation[key].label +
              " no supera la longitud de caracterers m\u00EDnima requerida." +
              "(ACTUAL:" +
              v_datos[validation[key].name].length +
              ",MIN:" +
              validation[key].maxLength +
              ").";
            toast.warning(error, { closeButton: true });
            isValid = false;
            validation[key].invalid = true;
          }
        }
      }
    }
  }
  resp = { isValid: isValid, validation: validation };

  return resp;
}

export async function getProductosByCliente(customerId, isValid) {
  var resp = { productos: [] };
  await axios({
    url: API_CONFIG.base_url + "/getProductosByCliente",
    method: "post",
    data: { customerId: customerId, isValid: isValid },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = {
        productos: data.result ? data.result : [],
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getProductosByClienteV2(NumDocumento, reservas) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/api/SolicitaPlanesServiciosAfiliado",
    data: {
      user: API_CONFIG.user,
      psw: API_CONFIG.paswword,
      NumDocumento: NumDocumento,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      //   console.log("data", data);
      if (data.Estado === "E") {
        var productos = [];
        if (data.data.Items) {
          data.data.Items.map(function (key) {
            let _iniy = key.FechaInicio.substr(0, 4);
            let _inim = key.FechaInicio.substr(4, 2);
            let _inid = key.FechaInicio.substr(6, 2);

            let _finy = key.FechaFin.substr(0, 4);
            let _finm = key.FechaFin.substr(4, 2);
            let _find = key.FechaFin.substr(6, 2);

            var prestaciones = [];
            //          console.log(key.Nombre);
            key.Prestaciones.map(function (p) {
              var _estado = "";
              var llaves = [];
              //              console.log("pres", p);
              let horarios = [];
              for (let r in reservas) {
                let h = reservas[r];
                //                console.log("chek",h.id_servicio, h.id_sesion   )
                if (h.id_servicio === p.Codigo && h.id_sesion === key.Codigo) {
                  let fecha_reserva = new Date(
                    h.descReserva.fecha_reserva + "T14:50:00Z"
                  );

                  let hora = h.descReserva.hora_reserva.substring(0, 5);

                  llaves.push({
                    key: llaves.length,
                    btnName: "Cambiar",
                    btnIcon: "fas fa-edit",
                    llave: h.llave,
                    /*serviceType:h.tipo_reserva,*/
                    nombre: h.descReserva.nombre_cliente,
                    apellido: h.descReserva.apellido_paterno,
                    CI: h.descReserva.ci_cliente,
                    telefono: h.descReserva.telefono_cliente,
                    fecha_str: h.descReserva.fecha_reserva,
                    hora_str: h.descReserva.hora_reserva,
                    id_horario: h.id_horario,
                    checkedVirtual: h.tipo_reserva === "virtual" ? true : false,
                    checkedPresencial:
                      h.tipo_reserva === "virtual" ? false : true,
                    fecha_reserva: fecha_reserva + " " + hora + ":00",
                  });

                  p["CantidadUtilizada"] = p.CantidadUtilizada + 1;
                }
              }

              if (p.CantidadPrestacion > p.CantidadUtilizada) {
                llaves.push({
                  key: 0,
                  btnName: "Agregar",
                  btnIcon: "fas fa-plus",
                });
                _estado = "DISPONIBLE";
              } else {
                _estado = "UTILIZADO";
              }

              prestaciones.push({
                key: p.Numero,
                id_cliente: data.data.idPaciente,
                id_servicio: p.Codigo,
                estado: _estado,
                llaves: llaves,
                reservas_activas: p.Horarios.length,
                servicio: p.Nombre,
                cantidad: p.CantidadPrestacion,
                atenciones_realizadas: p.CantidadUtilizada,
                es_virtual: "S",

                es_presencial: "S",
                nombres: data.data.Pnombre,
                Papellido: data.data.Papellido,
                Sapellido: data.data.Sapellido,
                CI: data.data.NumDocumento,
                dias_cambiar_cita: 1,
                dias_cancelar_cita: 1,
                horas_cambiar_cita: 1,
                horas_cancelar_cita: 1,
                disponible: p.CantidadPrestacion - p.CantidadUtilizada,
              });
            });
            productos.push({
              id_cliente: 1517, // key.IdPaciente
              nombre_cliente: data.data.Pnombre,
              nombre_cliente2: data.data.Snombre,
              apellido_paterno: data.data.Papellido,
              Genero: parseInt(data.data.Genero),
              telefono_cliente: data.data.Telefono,
              email_cliente: data.data.Email || "",
              apellido_materno: data.data.Sapellido,
              ci_cliente: data.data.NumDocumento,
              id_producto: key.Codigo,
              dias_vigencia: key.Vigencia == 12 ? 365 : key.Vigencia * 30,
              nombre_producto: key.Nombre,
              Beneficiarios: key.Beneficiarios,
              tipo_afiliado: "Afiliado",
              id_producto_cliente: key.FechaInicio,
              estado_producto_cliente: "A",
              fecha_nacimiento: data.data.FechaNaci + "T04:00:00.000Z",
              FechaNaci: data.data.FechaNaci,
              fecha_inicio:
                _iniy + "-" + _inim + "-" + _inid + "T04:00:00.000Z",
              fecha_fin: _finy + "-" + _finm + "-" + _find + "T04:00:00.000Z",
              prestaciones: prestaciones,
              Certificado: key.Certificado,
              Factura: key.Factura,
            });
          });
        }
        console.log("P", productos);
        resp = {
          Estado: "E",
          productos: productos,
        };
      } else {
        resp = {
          Estado: "X",
          error: data.data.DescEstado,
          productos: [],
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { Estado: "X", error: error, productos: [] };
    });
  return resp;
}

export async function getReservasByCliente(customerDocumentId, planId) {
  var resp = { reservas: [] };
  await axios({
    url: API_CONFIG.base_url + "/getReservasByCliente",
    method: "post",
    data: { customerDocumentId: customerDocumentId, planId: planId },
  })
    .then((response) => response.data)
    .then(async (data) => {
      var bookings = data.result;
      resp = {
        reservas: data.result ? bookings : [],
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getReservasByClienteV2(NumDocumento) {
  var resp = { reservas: [] };
  await axios({
    url: API_CONFIG.base_url + "/getReservasByClienteV2",
    method: "post",
    data: { NumDocumento: NumDocumento },
  })
    .then((response) => response.data)
    .then(async (data) => {
      var bookings = data.result;
      resp = {
        reservas: data.result ? bookings : [],
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getShopCartSessionCode(customerId) {
  var resp = { shopCartSessionID: null };
  await axios({
    url: API_CONFIG.base_url + "/payments/getShopCartSessionCode",
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      resp = {
        shopCartSessionID: data.shopCartSessionID,
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getPlans(planId) {
  var resp = { result: null };
  let token = "";
  await axios({
    url: API_CONFIG.base_url + "/getPlans/" + planId,
    headers: { Authorization: "Bearer " + token },
    method: "get",
  })
    .then((response) => response.data)
    .then((data) => {
      resp = {
        result: data.result,
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}
export async function getPlansv2(Canal, planId) {
  var resp = { result: null };
  let token = "";
  let data = {
    Tipo: 2,
  };
  if (Canal) {
    data["Canal"] = Canal;
  }
  await axios({
    url: API_CONFIG.base_url + "/api/SolicitaPlanesServicios",
    headers: { Authorization: "Bearer " + token },
    data: data,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      //		console.log("SolicitaPlanesServicios",data)
      if (planId) {
        var result = [];
        data.data.map(function (key) {
          if (key.Codigo == planId) {
            result.push(key);
          }
        });
        resp = {
          result: result,
        };
      } else {
        resp = {
          result: data.data,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getServices(serviceId) {
  var resp = { result: null };
  let token = "";
  await axios({
    url: API_CONFIG.base_url + "/getServices/" + serviceId,
    headers: { Authorization: "Bearer " + token },
    method: "get",
  })
    .then((response) => response.data)
    .then((data) => {
      resp = {
        result: data.result,
      };
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getPaymentStatus(shopCartSessionID) {
  var resp = {
    response: "X",
    descResponse: "",
    buttonMessage: "",
    orderPaid: null,
    refreshing: null,
  };
  let token = "";
  await axios({
    url: API_CONFIG.base_url + "/payments/getPaymentStatus",
    method: "post",
    data: { shopCartSessionID: shopCartSessionID },
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado == "E") {
        if (data.message == "PAGADO") {
          resp = {
            response: data.Estado,
            descResponse: data.message,
            buttonMessage: "Pedido Pagado",
            orderPaid: true,
            refreshing: false,
          };
        } else {
          resp = {
            response: data.Estado,
            descResponse: data.message,
            responseBackend: data.responseBackend,
            buttonMessage: "Procesando Pago",
            orderPaid: false,
            refreshing: true,
          };
        }
      } else {
        resp = {
          response: data.Estado,
          descResponse: data.message,
          orderPaid: false,
          refreshing: false,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function RegClienteOld(v_datos, toast) {
  var resp = { resp: "", desc: "" };
  var respuesta_final = "";
  var token = "0";

  let titular_last_name = v_datos.titular_last_name;
  let last_name = v_datos.last_name;

  if (titular_last_name.length > 1) {
    titular_last_name = titular_last_name.trim();
  }

  if (last_name.length > 1) {
    last_name = last_name.trim();
  }

  var Beneficiarios = [];
  Beneficiarios.push({
    Tipo: "1",
    DocIdentidad: v_datos.titular_ci_cliente + "",
    TipoDoc: "C",
    Papellido: titular_last_name,
    Sapellido: v_datos.titular_last_name_2,
    Pnombre: v_datos.titular_user_name,

    EstadoCivil: "S",
    Genero: v_datos.titular_radioGenero + "",
    Expedicion: v_datos.titular_expedicion,
    Extension: v_datos.titular_extension,
    Parentesco: "",
    FechaNaci:
      v_datos.titular_year + v_datos.titular_month + v_datos.titular_day,
    Telefono: v_datos.titular_phone !== "" ? v_datos.titular_phone : "0",
    Direccion:
      v_datos.titular_direccion !== "" ? v_datos.titular_direccion : "N/A",
    CiudadNac: v_datos.titular_ciudad,
    PaisNac: v_datos.titular_pais,
    Ocupacion: "ocupacion",
    CodOficina: "0205",
    DocIdentidadTitular: "0",
    Correo: v_datos.titular_email,
  });
  v_datos.v_beneficiarios.map(function (item) {
    Beneficiarios.push({
      Tipo: "2",
      DocIdentidad: item.benef_ci_cliente + "",
      TipoDoc: "C",
      Papellido: item.benef_last_name,
      Sapellido: item.benef_last_name_2,
      Pnombre: item.benef_user_name,
      EstadoCivil: "S",
      Genero: item.benef_radioGenero + "",
      Expedicion: item.benef_expedicion,
      Extension: item.benef_extension,
      Parentesco: item.benef_parentesco,
      FechaNaci: item.benef_year + item.benef_month + item.benef_day,
      Telefono: item.benef_phone,
      Direccion: item.benef_direccion !== "" ? item.benef_direccion : "N/A",
      CiudadNac: item.benef_ciudad,
      PaisNac: item.benef_pais,
      Ocupacion: "ocupacion",
      CodOficina: "0205",
      DocIdentidadTitular: v_datos.titular_ci_cliente,
      Correo: item.benef_email,
    });
  });

  var data = {
    user: API_CONFIG.user, //"UsuPorWeb",
    psw: API_CONFIG.paswword, //"InP0rW3b@20",
    Tipo: v_datos.tipo,
    Nit: v_datos.nit,
    RazonSocial: v_datos.nombre_factura,
    //"DocIdentidad":  v_datos.nit,
    TipoDoc: "C",
    DocIdentidad: v_datos.ci_cliente + "",
    Expedicion: v_datos.expedicion,
    Extension: v_datos.extension,
    Papellido: last_name,
    Sapellido: v_datos.last_name_2,
    Pnombre: v_datos.user_name,
    Correo: v_datos.email,
    Genero: v_datos.radioGenero + "",
    FechaNaci: v_datos.year + v_datos.month + v_datos.day,
    Telefono: v_datos.phone !== "" ? v_datos.phone : "0",
    Direccion: v_datos.direccion !== "" ? v_datos.direccion : "N/A",
    CiudadNac: v_datos.ciudad,
    PaisNac: v_datos.pais,
    CiudadRes: v_datos.ciudad,
    PaisRes: v_datos.pais,
    Sector: "1",
    Ocupacion: "ocupacion",
    CodOficina: "0205",
    Beneficiarios: Beneficiarios,
    DocIdentidadAsesor: v_datos.DocIdentidadAsesor,
    Canal: v_datos.Canal,
    CodServicio: v_datos.productId,
    DescServicio: v_datos.nombre_producto,
    MontoTotal: v_datos.amount,
    generateKey: true,
  };

  await axios({
    url: API_CONFIG.base_url + "/crediseguro/RegCliente",
    headers: { Authorization: "Bearer " + v_datos.paymentKey },
    data: data,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "X") {
        var error = data.message;
        toast.error(error, { closeButton: true });
        resp = { resp: "NOK", desc: data.message };
      } else {
        resp = {
          resp: "OK",
          desc: data.message,
          llave: data.token,
          NumIdentificador: data.NumIdentificador,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { resp: "NOK", desc: "ERROR DE SISTEMA:" + error };
    });
  return resp;
}

export async function RegClienteSAP(v_datos, toast) {
  var resp = { resp: "", desc: "" };
  var respuesta_final = "";
  var token = "0";

  var Beneficiarios = [];
  Beneficiarios.push({
    Tipo: "1",
    DocIdentidad: v_datos.titular_ci_cliente + "",
    TipoDoc: "C",
    Papellido: v_datos.titular_last_name,
    Sapellido: v_datos.titular_last_name_2,
    Pnombre: v_datos.titular_user_name,

    EstadoCivil: "S",
    Genero: v_datos.titular_radioGenero + "",
    Expedicion: v_datos.titular_expedicion,
    Extension: v_datos.titular_extension,
    //  "Parentesco":           "TITULAR",
    FechaNaci:
      v_datos.titular_year + v_datos.titular_month + v_datos.titular_day,
    Telefono: v_datos.titular_phone,
    Direccion: v_datos.titular_direccion,
    CiudadNac: v_datos.titular_ciudad,
    PaisNac: v_datos.titular_pais,
    Ocupacion: "ocupacion",
    CodOficina: "0205",
    DocIdentidadTitular: "0",
    Correo: v_datos.titular_email,
  });
  v_datos.v_beneficiarios.map(function (item) {
    Beneficiarios.push({
      Tipo: "2",
      DocIdentidad: item.benef_ci_cliente + "",
      TipoDoc: "C",
      Papellido: item.benef_last_name,
      Sapellido: item.benef_last_name_2,
      Pnombre: item.benef_user_name,
      EstadoCivil: "S",
      Genero: item.benef_radioGenero + "",
      Expedicion: item.benef_expedicion,
      Extension: item.benef_extension,
      Parentesco: item.benef_parentesco,
      FechaNaci: item.benef_year + item.benef_month + item.benef_day,
      Telefono: item.benef_phone,
      Direccion: item.benef_direccion,
      CiudadNac: item.benef_ciudad,
      PaisNac: item.benef_pais,
      Ocupacion: "ocupacion",
      CodOficina: "0205",
      DocIdentidadTitular: v_datos.titular_ci_cliente,
      Correo: item.benef_email,
    });
  });

  var data = {
    user: API_CONFIG.user,
    psw: API_CONFIG.paswword,
    Tipo: v_datos.tipo,
    Nit: v_datos.nit,
    RazonSocial: v_datos.nombre_factura,
    DocIdentidad: v_datos.nit,
    TipoDoc: "C",
    DocIdentidad: v_datos.ci_cliente + "",
    Expedicion: v_datos.expedicion,
    Extension: v_datos.extension,
    Papellido: v_datos.last_name,
    Sapellido: v_datos.last_name_2,
    Pnombre: v_datos.user_name,
    Correo: v_datos.email,
    Genero: v_datos.radioGenero + "",
    FechaNaci: v_datos.year + v_datos.month + v_datos.day,
    Telefono: v_datos.phone,
    Direccion: v_datos.direccion,
    CiudadNac: v_datos.ciudad,
    PaisNac: v_datos.pais,
    CiudadRes: v_datos.ciudad,
    PaisRes: v_datos.pais,
    Sector: "1",
    Ocupacion: "ocupacion",
    CodOficina: "PMSC02",
    Beneficiarios: Beneficiarios,
    DocIdentidadAsesor: v_datos.DocIdentidadAsesor,
    Canal: v_datos.Canal,
    CodServicio: v_datos.productId,
    DescServicio: v_datos.nombre_producto,
    MontoTotal: v_datos.amount,
    generateKey: true,
  };

  await axios({
    url: API_CONFIG.base_url + "/crediseguro/RegCliente_new",
    headers: { Authorization: "Bearer " + v_datos.paymentKey },
    data: data,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "X") {
        var error = data.message;
        toast.error(error, { closeButton: true });
        resp = { resp: "NOK", desc: data.message };
      } else {
        resp = {
          resp: "OK",
          desc: data.message,
          llave: data.token,
          NumIdentificador: data.NumIdentificador,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { resp: "NOK", desc: "ERROR DE SISTEMA:" + error };
    });
  return resp;
}

export async function preparaOrden(v_datos, toast) {
  var resp = { resp: "", desc: "" };
  var respuesta_final = "";
  var token = "0";

  await axios({
    url: API_CONFIG.base_url + "/preparaOrden",
    headers: { Authorization: "Bearer " + v_datos.paymentKey },
    data: {
      DocIdentidad: v_datos.ci_cliente,
      id_cliente: v_datos.id_cliente,
      Pnombre: v_datos.user_name,
      Papellido: v_datos.last_name,
      Sapellido: v_datos.last_name_2,
      Telefono: v_datos.phone,
      PatientEmail: v_datos.email,
      Genero: v_datos.radioGenero,
      FechaNaci: v_datos.year + v_datos.month + v_datos.day,
      Tipo: 2,
      TipoDoc: v_datos.TipoDoc,
      ci_afiliado: v_datos.ci_afiliado,
      titular_id_cliente: v_datos.titular_id_cliente,
      titular_DocIdentidad: v_datos.titular_ci_cliente,
      titular_Pnombre: v_datos.titular_user_name,
      titular_Papellido: v_datos.titular_last_name,
      titular_Sapellido: v_datos.titular_last_name_2,
      titular_Telefono: v_datos.titular_phone,
      titular_PatientEmail: v_datos.titular_email,
      titular_Genero: v_datos.titular_radioGenero,
      titular_FechaNaci:
        v_datos.titular_year + v_datos.titular_month + v_datos.titular_day,
      titular_Tipo: 1,
      titular_TipoDoc: v_datos.titular_TipoDoc,
      CodOficina: "0102",
      productos: v_datos.productos,
      shopCartSessionID: v_datos.shopCartSessionID,
      amount: v_datos.amount,
      razon_social: v_datos.nombre_factura,
      nit: v_datos.nit,
      productType: v_datos.productType,
    },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "X") {
        var error = data.message;
        toast.error(error, { closeButton: true });
        resp = { resp: "NOK", desc: data.message };
      } else {
        resp = {
          resp: "OK",
          desc: data.message,
          llave: data.llave,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { resp: "NOK", desc: "ERROR DE SISTEMA:" + error };
    });
  return resp;
}

export function getDays(day) {
  var array = [{ text: "Dia", value: "" }];
  for (var i = 1; i <= 31; i++) {
    array.push({ text: i, value: i < 10 ? "0" + i : i });
  }
  return array;
}
export function getMonths(month) {
  const array = [
    { value: "", text: "Mes" },
    { value: "01", text: "Enero" },
    { value: "02", text: "Febrero" },
    { value: "03", text: "Marzo" },
    { value: "04", text: "Abril" },
    { value: "05", text: "Mayo" },
    { value: "06", text: "Junio" },
    { value: "07", text: "Julio" },
    { value: "08", text: "Agosto" },
    { value: "09", text: "Septiembre" },
    { value: "10", text: "Octubre" },
    { value: "11", text: "Noviembre" },
    { value: "12", text: "Diciembre" },
  ];

  return array;
}
export function getYears(year) {
  var array = [{ text: "A\u00F1o", value: "" }];
  var f = new Date();
  var actual_year = f.getFullYear();

  for (var i = actual_year; i >= 1900; i--) {
    array.push({ text: i, value: i });
  }
  return array;
}
export async function enviarSMS(v_llave) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/enviarSMS",
    method: "post",
    data: { llave: v_llave, tipo_mensaje: "sms_registro_reserva" },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: "E" };
    });
  return resp;
}
export async function enviarMail(v_llave) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/enviarMail",
    method: "post",
    data: { llave: v_llave, tipo_mensaje: "correo_registro_reserva" },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: "E" };
    });
  return resp;
}

export async function enviarMailV2(v_llave, v_tipo_mensaje) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/enviarMailV2",
    method: "post",
    data: { llave: v_llave, tipo_mensaje: v_tipo_mensaje, doctor: "YES" },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: "E" };
    });
  return resp;
}
export async function enviarWspV2(v_llave, v_tipo_mensaje) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/enviarWspV2",
    method: "post",
    data: { llave: v_llave, tipo_mensaje: v_tipo_mensaje },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: "E" };
    });
  return resp;
}

export async function enviarWsp(v_llave) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/enviarWsp",
    method: "post",
    data: { llave: v_llave, tipo_mensaje: "wsp_registro_reserva" },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: "E" };
    });
  return resp;
}
export async function getValoresXDominio(v_dominio, v_nombre) {
  var resp = { Estado: "" };
  await axios({
    url: API_CONFIG.base_url + "/getValoresXDominio",
    method: "post",
    data: { dominio: v_dominio, nombre: v_nombre },
  })
    .then((response) => response.data)
    .then((data) => {
      resp = { Estado: data.Estado, rows: data.rows };
    });
  return resp;
}

export async function setUsers(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/setUsers",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: data.message };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}

export async function setReglas(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/setReglas",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: data.message };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}

export async function delReglas(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/delReglas",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: data.message };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}

export async function setServicios(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/setServicios",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: data.message };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}

export async function delServicios(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/delServicios",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, message: data.message };
      } else {
        resp = { success: false, message: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}
export async function getServicios() {
  var resp = { ciudades: [] };
  await axios({
    url: API_CONFIG.base_url + "/api/getServicios",
    data: { token: getCookie("tokenINNOVA") },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      resp = data;
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
    });
  return resp;
}
export async function getOrders(formData) {
  var resp;

  await axios({
    url: API_CONFIG.base_url + "/api/getOrders",
    data: formData,
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = {
          success: true,
          orders: data.orders,
          contratos: data.contratos,
        };
      } else {
        resp = {
          success: false,
          orders: [],
          contratos: [],
          error: data.message,
        };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}

export async function getUsers(filterAdmin) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/api/getUsers",
    data: { token: getCookie("tokenINNOVA") },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        let users = [];
        if (filterAdmin) {
          data.users.map(function (key, item) {
            if (key["valor1"] !== "Administrador") {
              users.push(key);
            }
          });
        } else {
          users = data.users;
        }

        users.sort((a, b) => {
          if (b.valor1 < a.valor1) return 1;
          if (b.valor1 > a.valor1) return -1;
        });
        resp = { success: true, users: users };
      } else {
        resp = { success: false, users: [], error: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, users: [], error: true };
    });
  return resp;
}
export async function loginInnova(usuario, password) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/api/loginUser",
    data: { usuario: usuario, password: password },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      if (data.Estado === "E") {
        resp = { success: true, error: false, user: data.user };
        console.log(data);
        setCookie("tokenINNOVA", data.token, 2400);

        setCookie("nombreUsuarioINNOVA", data.user.usuario, 2400);
        setCookie("ciUsuarioINNOVA", data.user.cedula, 2400);
        setCookie("rolINNOVA", data.user.valor1, 2400);
        setCookie("oficinaINNOVA", data.user.Cod_oficina, 2400);
        setCookie("empresaINNOVA", data.user.Cod_empresa, 2400);
      } else {
        resp = { success: false, error: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, error: true };
    });
  return resp;
}

export async function checkPatient(carnet_identidad) {
  var resp;
  await axios({
    url: API_CONFIG.base_url + "/api/SolicitaPlanesServiciosAfiliado",
    data: { DocIdentidad: carnet_identidad },
    method: "post",
  })
    .then((response) => response.data)
    .then((data) => {
      console.log(data);
      if (data.Estado === "E") {
        resp = { success: true, error: false };
      } else {
        resp = { success: false, error: data.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      resp = { success: false, error: true };
    });
  return resp;
}

export async function checkPlanPatient(carnet_identidad) {
  var _resp;
  await axios({
    url: API_CONFIG.base_url + "/api/SolicitaPlanesServiciosAfiliado",
    data: { NumDocumento: carnet_identidad },
    method: "post",
  })
    .then((response) => response.data)
    .then((resp) => {
      if (
        resp.data.Estado === "E" ||
        resp.data.NumDocumento == carnet_identidad
      ) {
        _resp = { success: true, error: false, data: resp.data };
      } else {
        _resp = { success: false, error: resp.message };
      }
    })
    .catch(function (error) {
      console.log(">>>>>>>>", error);
      _resp = { success: false, error: true };
    });
  return _resp;
}

export async function logoutInnova() {
  var resp = { success: true };
  setCookie("tokenINNOVA", null, 0.001);
  setCookie("nombreUsuarioINNOVA", null, 0.001);
  setCookie("ciUsuarioINNOVA", null, 0.001);

  return resp;
}

export function setCookie(name, value, hours) {
  try {
    var expires;
    if (hours) {
      var date = new Date();
      date.setTime(date.getTime() + hours * 60 * 60 * 1000);
      expires = "; expires=" + date.toGMTString();
    } else {
      expires = "";
    }

    // console.log(value)
    document.cookie = name + "=" + value + expires + "; path=/";
  } catch (err) {
    console.log(">>>>>>> ERR SETTING COOKIE", err);
  }
}

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  var resp;
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    if (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
      console.log("ENC");
    }
  }
  return resp;
}

export async function cerrarVenta(id_orden) {
  var resp = {};
  await axios({
    url: API_CONFIG.base_url + "/cerrarVenta",
    method: "post",
    data: { id_orden: id_orden },
  })
    .then((response) => response.data)
    .then(async (data) => {
      resp = data;
    })
    .catch(function (error) {
      resp = {
        Estado: "X",
        message: "Error interno:" + error,
      };
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function getResponseBackEndEfectivo(order_id, DocIdentidadAsesor) {
  var resp = {};
  await axios({
    url: API_CONFIG.base_url + "/payments/getResponseBackEndEfectivo",
    method: "post",
    data: {
      DocIdentidadAsesor: DocIdentidadAsesor,
      id_orden: order_id,
    },
  })
    .then((response) => response.data)
    .then(async (data) => {
      resp = data;
    })
    .catch(function (error) {
      resp = {
        Estado: "X",
        message: "Error interno:" + error,
      };
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function validarVenta(id_orden) {
  var resp = {};
  await axios({
    url: API_CONFIG.base_url + "/validarVenta",
    method: "post",
    data: { id_orden: id_orden },
  })
    .then((response) => response.data)
    .then(async (data) => {
      console.log("data", data);
      resp = data;
    })
    .catch(function (error) {
      resp = {
        Estado: "X",
        message: "Error interno:" + error,
      };
      console.log(">>>>>>>>", error);
    });
  return resp;
}

export async function conciliarVenta(id_orden) {
  var resp = {};
  await axios({
    url: API_CONFIG.base_url + "/conciliarVenta",
    method: "post",
    data: { id_orden: id_orden },
  })
    .then((response) => response.data)
    .then(async (data) => {
      console.log("data", data);
      resp = data;
    })
    .catch(function (error) {
      resp = {
        Estado: "X",
        message: "Error interno:" + error,
      };
      console.log(">>>>>>>>", error);
    });
  return resp;
}
export function getPaises() {
  var countryList = [
    "Bolivia",
    "Afganist\u00E1n",
    "Albania",
    "Alemania",
    "Andorra",
    "Angola",
    "Anguila",
    "Ant\u00E1rtida",
    "Antigua y Barbuda",
    "Antillas holandesas",
    "Arabia Saud\u00ED",
    "Argelia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaiy\u00E1n",
    "Bahamas",
    "Bahrein",
    "Bangladesh",
    "Barbados",
    "B\u00E9lgica",
    "Belice",
    "Ben\u00EDn",
    "Bermudas",
    "Bhut\u00E1n",
    "Bielorrusia",
    "Birmania",
    "Bosnia y Herzegovina",
    "Botsuana",
    "Brasil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cabo Verde",
    "Camboya",
    "Camer\u00FAn",
    "Canad\u00E1",
    "Chad",
    "Chile",
    "China",
    "Chipre",
    "Ciudad estado del Vaticano",
    "Colombia",
    "Comores",
    "Congo",
    "Corea",
    "Corea del Norte",
    "Costa del Marf\u00EDl",
    "Costa Rica",
    "Croacia",
    "Cuba",
    "Dinamarca",
    "Djibouri",
    "Dominica",
    "Ecuador",
    "Egipto",
    "El Salvador",
    "Emiratos Arabes Unidos",
    "Eritrea",
    "Eslovaquia",
    "Eslovenia",
    "Espa�a",
    "Estados Unidos",
    "Estonia",
    "Ex-Rep\u00FAblica Yugoslava de Macedonia",
    "Filipinas",
    "Finlandia",
    "Francia",
    "Gab\u00F3n",
    "Gambia",
    "Georgia",
    "Georgia del Sur y las islas Sandwich del Sur",
    "Ghana",
    "Gibraltar",
    "Granada",
    "Grecia",
    "Groenlandia",
    "Guadalupe",
    "Guam",
    "Guatemala",
    "Guayana",
    "Guayana francesa",
    "Guinea",
    "Guinea Ecuatorial",
    "Guinea-Bissau",
    "Hait\u00ED",
    "Holanda",
    "Honduras",
    "Hong Kong R. A. E",
    "Hungr\u00EDa",
    "India",
    "Indonesia",
    "Irak",
    "Ir\u00E1n",
    "Irlanda",
    "Isla Bouvet",
    "Isla Christmas",
    "Isla Heard e Islas McDonald",
    "Islandia",
    "Islas Caim\u00E1n",
    "Islas Cook",
    "Islas de Cocos o Keeling",
    "Islas Faroe",
    "Islas Fiyi",
    "Islas Malvinas Islas Falkland",
    "Islas Marianas del norte",
    "Islas Marshall",
    "Islas menores de Estados Unidos",
    "Islas Palau",
    "Islas Salom\u00F3n",
    "Islas Tokelau",
    "Islas Turks y Caicos",
    "Islas V\u00EDrgenes EE.UU.",
    "Islas V\u00EDrgenes Reino Unido",
    "Israel",
    "Italia",
    "Jamaica",
    "Jap\u00F3n",
    "Jordania",
    "Kazajist\u00E1n",
    "Kenia",
    "Kirguizist\u00E1n",
    "Kiribati",
    "Kuwait",
    "Laos",
    "Lesoto",
    "Letonia",
    "L\u00EDbano",
    "Liberia",
    "Libia",
    "Liechtenstein",
    "Lituania",
    "Luxemburgo",
    "Macao R. A. E",
    "Madagascar",
    "Malasia",
    "Malawi",
    "Maldivas",
    "Mal\u00ED",
    "Malta",
    "Marruecos",
    "Martinica",
    "Mauricio",
    "Mauritania",
    "Mayotte",
    "M\u00E9xico",
    "Micronesia",
    "Moldavia",
    "M\u00F3naco",
    "Mongolia",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "N\u00EDger",
    "Nigeria",
    "Niue",
    "Norfolk",
    "Noruega",
    "Nueva Caledonia",
    "Nueva Zelanda",
    "Om\u00E1n",
    "Panam\u00E1",
    "Papua Nueva Guinea",
    "Paquist\u00E1n",
    "Paraguay",
    "Per\u00FA",
    "Pitcairn",
    "Polinesia francesa",
    "Polonia",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reino Unido",
    "Rep\u00FAblica Centroafricana",
    "Rep\u00FAblica Checa",
    "Rep\u00FAblica de Sud\u00E1frica",
    "Rep\u00FAblica Democr\u00E1tica del Congo Zaire",
    "Rep\u00FAblica Dominicana",
    "Reuni\u00F3n",
    "Ruanda",
    "Rumania",
    "Rusia",
    "Samoa",
    "Samoa occidental",
    "San Kitts y Nevis",
    "San Marino",
    "San Pierre y Miquelon",
    "San Vicente e Islas Granadinas",
    "Santa Helena",
    "Santa Luc\u00EDa",
    "Santo Tom\u00E9 y Pr\u00EDncipe",
    "Senegal",
    "Serbia y Montenegro",
    "Sychelles",
    "Sierra Leona",
    "Singapur",
    "Siria",
    "Somalia",
    "Sri Lanka",
    "Suazilandia",
    "Sud\u00E1n",
    "Suecia",
    "Suiza",
    "Surinam",
    "Svalbard",
    "Tailandia",
    "Taiw\u00E1n",
    "Tanzania",
    "Tayikist\u00E1n",
    "Territorios brit\u00E1nicos del oc\u00E9ano Indico",
    "Territorios franceses del sur",
    "Timor Oriental",
    "Togo",
    "Tonga",
    "Trinidad y Tobago",
    "T\u00FAnez",
    "Turkmenist\u00E1n",
    "Turqu\u00EDa",
    "Tuvalu",
    "Ucrania",
    "Uganda",
    "Uruguay",
    "Uzbekist\u00E1n",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis y Futuna",
    "Yemen",
    "Zambia",
    "Zimbabue",
  ];
  return countryList;
}
