import React, { Component } from "react";
class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapseID: "collapse1",
      direccion: "",
      ubicacion: "",
      tipoAcc: "Comprar",
    };
  }

  componentDidMount = async () => {};

  render() {
    return <div>Página no encontrada</div>;
  }
}
export default Home;
