const API_CONFIG =  {base_url: process.env.REACT_APP_API_CONFIG,
										 user: process.env.REACT_APP_API_USER,
										 paswword: process.env.REACT_APP_API_PASS,
										 qr_api: process.env.REACT_APP_QR_API } ;
const CYBER_org_id= process.env.REACT_APP_CYBER_org_id;
export {
    API_CONFIG,
	CYBER_org_id
  }
  
//test http://cicdinnovap1test-env.eba-pqm94ukp.us-east-1.elasticbeanstalk.com
//prod  http://innovap1.us-east-1.elasticbeanstalk.com  = https://back.innovasalud.bo 