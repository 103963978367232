/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_CONFIG } from "../Config/config-env.js";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
const Home = () => {
  const { qr_id } = useParams();

  // Renderiza el componente de clase Home y pasa los parámetros de la URL como props
  return <Class qr_id={qr_id} />;
};

class Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      qr_id: "",
      amount: 0,
      order_id: "",
      isValid: true,
      company_name: "",
      card_expiry_date: "",
      loading: true,
      URLCYBER: "",
      invalid: false,
      image_path: "",
    };
  }

  componentDidMount = async () => {
    console.log(this.props);
    const { qr_id, message } = this.props;

    await axios({
      url: API_CONFIG.base_url + "/validateQr",
      data: {
        qr_id: qr_id,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => {
        this.setState({
          loading: false,
        });

        if (data.status) {
          this.setState({
            company_code: data.data.qr_source,
            amount: data.data.amount,
            order_id: data.data.order_id,
            qr_id: data.data.id,
            image_path: data.data.image_path,
            message: message || data.data.error_message,
          });
        } else {
          if (data.code === "REDIRET") {
            console.log("aqui");
            this.setState({
              redirect: true,
              company_code: data.data.qr_source,
              qr_id: data.data.id,
            });
          } else {
            this.setState({
              invalid: true,
            });
          }
        }
      })
      .catch(function (error) {
        this.setState({
          invalid: true,
        });
        console.log(">>>>>>>>", error);
      });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Navigate
          to={
            "/api/v1/request/" +
            this.state.company_code +
            "/" +
            this.state.qr_id
          }
        />
      );
    }
  };

  render() {
    return (
      <>
        <Loader idLoading={this.state.loading} />
        {this.renderRedirect()}
        {this.state.invalid ? (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              {" "}
              Pasarela de pagos{" "}
            </h1>
            <div className="ccontainer">
              <div className="ccards">
                <div className="ccard header-card" style={{ border: 0 }}>
                  <h3 style={{ textAlign: "center" }}>
                    Lo sentimos, no encontramos su reseva
                  </h3>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              Error en el pago
            </h1>

            <div className="ccontainer">
              {!this.state.loading && (
                <div className="ccards">
                  <div className="ccard header-card"></div>
                  <div className="ccard image-card">
                    <>
                      <h3 style={{ textAlign: "center" }}>
                        Lo sentimos, hubo un error procesando su pago,{" "}
                        <a
                          style={{ textDecoration: "underline" }}
                          href={
                            "/api/v1/request/" +
                            this.state.company_code +
                            "/" +
                            this.state.qr_id
                          }
                        >
                          {" "}
                          haga click aquí 
                        </a> para intentarlo nuevamente
                        <div class="contenedor-btn">
                          <button class="btn-trash">
                            <i class="fas fa-exclamation"></i>
                          </button>
                        </div>
                      </h3>

                      <div style={{ textAlign: "center" }}>
                        El mensaje de error recibido fue:{" "}
                        <span style={{ fontWeight: 700 }}>
                          {this.state.message}{" "}
                        </span>
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
export default Home;
