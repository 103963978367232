import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import GererateRequest from "./pages/GererateRequest";
import Request from "./pages/Request";
import NotFound from "./pages/NotFound";
import ThanksPage from "./pages/ThanksPage";
import ImageQR from "./pages/ImageQR";
import ErrrorPago from "./pages/ErrrorPago";
import "./assets/css/main.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
function AppContainer() {
  return (
    <Router>
      <div>
        {/* Renderiza el header en todas las páginas */}

        <Routes>
          {/* Rutas para la página de inicio */}
          <Route
            exact
            path="/api/v1/generate-request/:company_code"
            element={<GererateRequest />} // Usa la clase Home como componente
          />
          <Route
            exact
            path="/api/v1/generate-request/:company_code/:order_id"
            element={<GererateRequest />} // Usa la clase Home como componente
          />

          <Route
            exact
            path="/api/v1/request/:company_code"
            element={<Request />} // Usa la clase Home como componente
          />
          <Route
            exact
            path="/api/v1/request/:company_code/:qr_id"
            element={<Request />} // Usa la clase Home como componente
          />
          <Route
            exact
            path="/api/v1/pago-satisfactorio/:qr_id"
            element={<ThanksPage />} // Usa la clase Home como componente
          />

          <Route
            exact
            path="/api/v1/error-pago/:qr_id"
            element={<ErrrorPago />} // Usa la clase Home como componente
          />
          <Route
            exact
            path="/api/v1/error-pago/:qr_id/:message"
            element={<ErrrorPago />} // Usa la clase Home como componente
          />

          <Route
            exact
            path="/api/v1/qr-image/:qr_id"
            element={<ImageQR />} // Usa la clase Home como componente
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppContainer;
