/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_CONFIG } from "../Config/config-env.js";
import { Navigate } from "react-router-dom";
import Loader from "../components/Loader";
const Home = () => {
  const { qr_id } = useParams();

  // Renderiza el componente de clase Home y pasa los parámetros de la URL como props
  return <Class qr_id={qr_id} />;
};

class Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      qr_id: "",
      amount: 0,
      order_id: "",
      isValid: true,
      company_name: "",
      card_expiry_date: "",
      loading: true,
      URLCYBER: "",
      invalid: false,
      image_path: "",
    };
  }

  componentDidMount = async () => {
    console.log(this.props);
    const { qr_id } = this.props;
    
    await axios({
      url: API_CONFIG.base_url + "/validateQr",
      data: {
        qr_id: qr_id,
      },
      method: "post",
      dataType: "json",
    })
      .then((response) => response.data)
      .then((data) => { 
        this.setState({
           loading: false,
        });

        if (data.status) {
          this.setState({
            company_code: data.data.qr_source,
            amount: data.data.amount,
            order_id: data.data.order_id,
            image_path: data.data.image_path,
          });
        } else {
          if (data.code === "REDIRET") {
            console.log("aqui");
            this.setState({
              redirect: true,
              company_code: data.data.qr_source,
              qr_id: data.data.id,
            });
          } else {
            this.setState({
              invalid: true,
            });
          }
        }
      })
      .catch(function (error) {
        this.setState({
          invalid: true,
        });
        console.log(">>>>>>>>", error);
      });
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Navigate
          to={
            "/api/v1/request/" +
            this.state.company_code +
            "/" +
            this.state.qr_id
          }
        />
      );
    }
  };

  render() {
    return (
      <>
        <Loader idLoading={this.state.loading} />
        {this.renderRedirect()}
        {this.state.invalid ? (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              {" "}
              Confirmación de pago{" "}
            </h1>
            <div className="ccontainer">
              <div className="ccards">
                <div className="ccard header-card" style={{ border: 0 }}>
                  <h3 style={{ textAlign: "center" }}>
                    Lo sentimos, no encontramos su reseva
                  </h3>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              {" "}
              Confirmación de pago{" "}
            </h1>

            <div className="ccontainer">
              {!this.state.loading && (
                <div className="ccards">
                  <div
                    className="ccard header-card"
                    style={{ border: 0, padding: 18 }}
                  >
                    <h1 style={{ textAlign: "center" }}>
                      Su pago ha sido procesado correctamente
                      <div className="contenedor-btn">
                        <button className="btn-check">
                          <i className="fas fa-check"></i>
                        </button>
                      </div>
                    </h1>
                  </div>
                  <div className="ccard image-card">
                    <>
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Monto pagado: Bs <b>
                          {this.state.amount.toFixed(2)}
                        </b>{" "}
                      </h3>
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Nro Reserva: <b>{this.state.order_id}</b>{" "}
                      </h3>
                      <h4 style={{ textAlign: "center" }}>
                        {" "}
                        Se envió un correo con la confirmacíon del pago{" "}
                      </h4>
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{
                            width: "100%",
                            maxWidth: 250,
                            maxHeight: "100%",
                            objectFit: "cover",
                          }}
                          src={
                            this.state.image_path !== ""
                              ? this.state.image_path
                              : "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBg8IBwgSExASFRIQEQ8SEA8VDRUQFREWFhcVFRUYHSggGBolGxUVITEhJSk3Li4uFx8zODM4NygtLisBCgoKDQ0OGg0PFzgfHRk3Lis3Ky0tNy0rLTc3Ky0rLSsrLS0tKy43LS0tKzctLTItNzctNysrLTcrLSstMi03K//AABEIAOEA4QMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIHA//EAB4QAQEBAQACAgMAAAAAAAAAAAABETEhQRJxAlFh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwDuIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAF8Qib+iaCiSqAAAAAAAAAAAAAAAAAAAAAAAAAlUBJxak32oJL43DfCgJ3qp5PsD2qZVAAAAAAAAAAAAAAAAAAAAAKAMzeltXIXgpuTaSy8rF/C/HP16rHw+P5bufycFmPdPOk4oyJ24rGeRWxJxRAAAAAAAAAAAAAAAAAAAAAAE9mqAnS8UBJd4k7xpLcBL8vSzb0+iTBVAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//Z"
                          }
                        />
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
}
export default Home;
