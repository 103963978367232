import React from "react";
import { MDBSpinner } from "mdb-react-ui-kit";
const loadMessage = (props) => {
  return (
    <>
      {props.idLoading ? (
        <>
          <div className="loadingBG"></div>

          <div className="loadingBG2">
            <div className="loading">
              <MDBSpinner
                grow
                color="danger"
                style={{ width: "2rem", height: "2rem", marginLeft: "1rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
              <MDBSpinner
                grow
                color="warning"
                style={{ width: "2rem", height: "2rem", marginLeft: "1rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
              <MDBSpinner
                grow
                color="success"
                style={{ width: "2rem", height: "2rem", marginLeft: "1rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </MDBSpinner>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default loadMessage;
