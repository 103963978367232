/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { useParams } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { generatePayment } from "../utils/functions";
import Loader from "../components/Loader";
import { isMobile } from "../utils/functions";
import { MDBSpinner } from "mdb-react-ui-kit";
const Home = () => {
  const { company_code, order_id } = useParams();

  // Renderiza el componente de clase Home y pasa los parámetros de la URL como props
  return <Class company_code={company_code} order_id={order_id} />;
};

class Class extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: "",
      order_id: "",
      company_code: "",
      qr_id: "",
      isValid: true,
      loading: true,
      hideOrder: false,
    };
  }

  changeHandler = async (event) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value.trim(),
    });
  };

  redirectToLogin = async (event) => {
    if (event.key === "Enter") {
      await this.validateOrder();
    }
  };
  redirectToLoginB = async (event) => {
    if (this.state.order_id.length >= 5) {
      await this.validateOrder();
    } else {
      this.setState({
        errorMessage: "Ingrese una reserva válida",
      });
    }
  };
  validateOrder = async (event) => {
    this.setState({
      hideOrder: true,
      errorMessage: "",
    });
    if (this.state.order_id.length >= 5) {
      let resp = await generatePayment(
        this.state.company_code,
        this.state.order_id
      );
      console.log("goB", resp);
      if (resp.status) {
        this.setState({
          redirect: true,
          qr_id: resp.qr_id,
        });
      } else {
        console.log(resp);
        if (resp.code === "PAID") {
          this.setState({
            wasPaid: true,
            hideOrder: false,
          });
        }
        if (resp.code === "NOCOMPANY" || resp.code === "NOORDER") {
          this.setState({
            errorMessage: resp.message,
            hideOrder: false,
          });
        }
      }
    } else {
      console.log("no");
    }

    this.setState({
      loading: false,
    });
  };
  componentDidMount = async () => {
    if (!isMobile()) {
      const pos = 125;
      let time = 500;
      var currentPos = window.scrollY || window.pageYOffset;
      var start = null;
      time = time || 500;

      function animation(currentTime) {
        if (!start) start = currentTime;
        var progress = currentTime - start;
        var newPos = Math.min(
          (progress / time) * (pos - currentPos) + currentPos,
          pos
        );
        window.scrollTo(0, newPos);
        if (progress < time) {
          window.requestAnimationFrame(animation);
        }
      }

      window.requestAnimationFrame(animation);
    }

    const { company_code, order_id } = this.props;

    this.setState({ company_code: company_code });

    if (order_id) {
      this.setState({ order_id: order_id });
    } else {
      console.log("no");
    }
    if (company_code && order_id) {
      console.log("check");
      this.setState({ hideOrder: true });

      setTimeout(
        async function () {
          await this.validateOrder();
        }.bind(this),
        10
      );
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  renderRedirect = () => {
    if (this.state.redirect && this.state.order_id.length >= 5) {
      console.log("go");
      return (
        <Navigate
          to={
            this.state.qr_id !== ""
              ? "/api/v1/request/" +
                this.state.company_code +
                "/" +
                this.state.qr_id
              : "/api/v1/generate-request/" + this.state.company_code
          }
        />
      );
    }
  };

  render() {
    return (
      <>
        {this.renderRedirect()}

        {this.state.wasPaid ? (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              {" "}
              Confirmación de pago{" "}
            </h1>

            <div className="ccontainer">
              {!this.state.loading && (
                <div className="ccards" style={{ width: "85%" }}>
                  <div
                    className="ccard header-card"
                    style={{ border: 0, padding: 18 }}
                  >
                    <h1 style={{ textAlign: "center" }}>
                      Su pago ya ha sido pagado y procesado correctamente
                      <div className="contenedor-btn">
                        <button className="btn-check">
                          <i className="fas fa-check"></i>
                        </button>
                      </div>
                    </h1>
                  </div>
                  <div className="ccard image-card">
                    <>
                      <h3 style={{ textAlign: "center" }}>
                        {" "}
                        Nro Reserva: <b>{this.state.order_id}</b>{" "}
                      </h3>

                      <h4 style={{ textAlign: "center" }}>
                        <a
                          href="#"
                          onClick={() => {
                            this.setState({ wasPaid: false });
                          }}
                        >
                          <span style={{ textDecoration: "underline" }}>
                            Buscar nuevamente
                          </span>
                        </a>
                      </h4>

                      <div style={{ textAlign: "center",marginBottom:20 }}>
                        <img
                          className="image-logo"
                          src={
                            this.state.image_path !== ""
                              ? this.state.image_path
                              : "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBwgHBg8IBwgSExASFRIQEQ8SEA8VDRUQFREWFhcVFRUYHSggGBolGxUVITEhJSk3Li4uFx8zODM4NygtLisBCgoKDQ0OGg0PFzgfHRk3Lis3Ky0tNy0rLTc3Ky0rLSsrLS0tKy43LS0tKzctLTItNzctNysrLTcrLSstMi03K//AABEIAOEA4QMBIgACEQEDEQH/xAAYAAEBAQEBAAAAAAAAAAAAAAAAAQIHA//EAB4QAQEBAQACAgMAAAAAAAAAAAABETEhQRJxAlFh/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oADAMBAAIRAxEAPwDuIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAF8Qib+iaCiSqAAAAAAAAAAAAAAAAAAAAAAAAAlUBJxak32oJL43DfCgJ3qp5PsD2qZVAAAAAAAAAAAAAAAAAAAAAKAMzeltXIXgpuTaSy8rF/C/HP16rHw+P5bufycFmPdPOk4oyJ24rGeRWxJxRAAAAAAAAAAAAAAAAAAAAAAE9mqAnS8UBJd4k7xpLcBL8vSzb0+iTBVAEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAf//Z"
                          }
                        />
                      </div>
                    </>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <h1 className="title" style={{ textAlign: "center" }}>
              Pasarela de Pagos
            </h1>
            <div className="ccontainer">
              <div className="ccards">
                <div
                  className="ccard header-card"
                  style={{ border: 0, padding: 18 }}
                >
                  <h1
                    className="c-h1"
                    style={{ textAlign: "center", marginBottom: 10 }}
                  >
                    {!this.state.hideOrder
                      ? "Por favor ingrese su número de reserva"
                      : "Buscando los datos de tu orden, espera un momento"}

                    {!this.state.hideOrder ? (
                      <div className="contenedor-btn">
                        <div id="formulario">
                          <div>
                            <label for="order_id">Nro Reserva</label>
                            <input
                              type="text"
                              id="order_id"
                              name="order_id"
                              onKeyPress={this.redirectToLogin}
                              onChange={this.changeHandler}
                              value={this.state.order_id}
                            />
                          </div>
                          <div>
                            <button
                              type="submit"
                              className="btn-enviar"
                              onClick={this.redirectToLoginB}
                            >
                              Buscar
                            </button>
                          </div>
                          <h6>{this.state.errorMessage}</h6>
                        </div>
                      </div>
                    ) : (
                      <div className="contenedor-loader">
                        <MDBSpinner
                          grow
                          color="danger"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            marginLeft: "1rem",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                        <MDBSpinner
                          grow
                          color="warning"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            marginLeft: "1rem",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                        <MDBSpinner
                          grow
                          color="success"
                          style={{
                            width: "2rem",
                            height: "2rem",
                            marginLeft: "1rem",
                          }}
                        >
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      </div>
                    )}
                  </h1>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default Home;
